/* General Styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #d4d4d4;
  color: #000000;
  position: relative;
  z-index: 1000;
}

.navbar-logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo {
  width: 250px;
  height: 80px;
  margin-right: 10px;
}

.website-name {
  font-size: 1.2em;
  font-weight: bold;
}

/* Navbar Links */
.navbar-links {
  display: flex;
  list-style: none;
  gap: 20px;
  transition: max-height 0.3s ease;
}

.navbar-links li {
  cursor: pointer;
  color: #000000;
  font-weight: 500;
  font-size: 1em;
  transition: color 0.3s ease;
}

.navbar-links li:hover {
  color: #203620;
}

/* Hamburger Menu */
.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s ease;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .navbar {
    padding: 10px;
  }

  .navbar-logo {
    flex: 1;
  }

  .navbar-links {
    position: absolute;
    top: 100px;
    left: 0;
    width: 100%;
    flex-direction: column;
    background-color: #b8b8b8;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
  }

  .navbar-links.open {
    max-height: 500px; /* Adjust as necessary */
  }

  .navbar-links li {
    padding: 10px;
    text-align: center;
    width: 100%;
  }

  .hamburger {
    display: flex;
  }

  .hamburger.open .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.open .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.open .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
