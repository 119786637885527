

.about-us-main-heading {
    font-size: 32px;
    text-align: center;
    color: #333;
    margin-bottom: 30px;
}

.about-us-content {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 20px;
}

.about-us-left-section {
    flex: 2;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
}

.about-us-left-section h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
}

.about-us-left-section p {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
}

.about-us-right-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
}

.about-us-doctor-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 20px;
}

.about-us-doctor-name {
    font-size: 22px;
    color: #333;
    margin-bottom: 15px;
}

.about-us-locations {
    list-style-type: none;
    padding: 0;
    margin: 0;
    color: #555;
    font-size: 14px;
    text-align: center;
}

.about-us-locations li {
    margin: 5px 0;
    text-align: left;
}

.about-us-appointment-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #09751f;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.about-us-appointment-button:hover {
    background-color: #0056b3;
}

/* Mission & Vision Section Styles */
.about-us-mission-vision {
    margin-top: 40px;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
}

.about-us-mission-vision-heading {
    font-size: 28px;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.about-us-mission-heading,
.about-us-vision-heading {
    font-size: 24px;
    color: #333;
    margin-top: 20px;
}

.about-us-mission-text,
.about-us-vision-text {
    font-size: 16px;
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
}
@media (max-width: 768px) {
    .about-us-content {
        display: flex;
        flex-direction: column-reverse;
    }
    
}
