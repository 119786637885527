.home-about {
    padding: 40px 20px;
    background-color: #f8f9fa; /* Light grey background for a professional look */
}

.home-about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.home-about-heading {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 30px;
    color: #333;
}

.home-about-body {
    display: block; /* Changed to block for desktop alignment */
    width: 100%;
    max-width: 1200px;
    margin: 0 auto; /* Center-align the container */
}

.home-about-text {
    width: 60%; /* Text covers 60% of the container on desktop */
    float: left; /* Align text to the left */
    margin-right: 20px;
    text-align: justify;
    font-size: 1em;
    color: #555;
    box-sizing: border-box;
}

.home-about-image-box {
   
    float: right; /* Align image to the right */
    text-align: center;

    box-sizing: border-box;
}

.home-about-doctor-image {
    width: 100%; /* Ensures image takes full width of its container */
    max-width: 300px; /* Optional, to control max width on larger screens */
    height: auto;
    margin-bottom: 15px;
    display: block;
}

.home-about-doctor-name {
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
}

.home-about-appointment-button {
    background-color: #28a745; /* Green color */
    color: #fff;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.4), 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.home-about-appointment-button:hover {
    background-color: #218838; /* Darker green on hover */
}

@media (max-width: 768px) {
    .home-about-body {
        display: flex; /* Flex for mobile view */
        flex-direction: column-reverse;
    }

    .home-about-text {
        width: 100%; /* Full width on mobile */
        margin: 20px 0;
        float: none;
    }

    .home-about-image-box {
        width: 100%; /* Full width on mobile */
        margin: 0;
        float: none;
    }

    .home-about-doctor-image {
        width: 100%; /* Image should take full width on mobile */
        max-width: none; /* Removes any max width restriction */
    }

    .home-about-appointment-button {
        width: 100%;
        border-radius: 0; /* No border radius for a clean, sharp look */
    }
}
