
.homepage-container {
    display: none;
    align-items: flex-start;
    justify-content: center;
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: relative;
    /* padding: 10px 20px; */
  }
  
  .homepage-overlay {
    background-color: rgba(0, 0, 0, 0.5); /* Add a dark overlay for better text visibility */
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  
  .homepage-content {
    color: #fff; /* Make the text white for better contrast */
    text-align: left;
    max-width: 600px;
    padding: 20px;
  }
  
  .homepage-heading {
    font-size: 2em;
    margin-bottom: 15px;
    font-weight: bold;
  }
  
  .homepage-pointers {
    list-style-type: none; /* Remove default bullet points */
    padding-left: 0;
  }
  
  .homepage-pointers li {
    position: relative;
    padding-left: 5px;
    margin-bottom: 10px;
    font-size: 1.2em;
  }
  
  .homepage-pointers li::before {
    position: absolute;
    left: 0;
    color: #25D366; /* Set a color for the arrow */
    font-weight: bold;
    font-size: 1.5em;
  }
  
  .homepage-book-button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: #25D366;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-weight: bold;
    border-radius: 5px;
    margin-left: 15px;
  }
  
  .homepage-book-button:hover {
    background-color: #128C7E;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .homepage-container {
      padding: 0px 0px;
      height: 90vh;
    }
  
    .homepage-content {
      padding: 10px;
    }
  
    .homepage-heading {
      font-size: 1.8em;
    }
  
    .homepage-pointers li {
      font-size: 1em;
    }
  
    .homepage-book-button {
      font-size: 0.9em;
      padding: 8px 16px;
    }
  }
  