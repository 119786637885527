.home-testimonials {
    /* max-width: 800px; */
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    background-color: #f8f9fa;
  }
  
  .testimonials-heading {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .testimonial-card {
    padding: 10px;
    text-align: center;
  }
  
  .testimonial-image {
    max-width: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  
  .testimonial-stars {
    color: gold;
    font-size: 18px;
    margin: 5px 0;
  }
  
  .testimonial-name {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .testimonial-description {
    font-size: 14px;
    margin-top: 5px;
    color: #555;
  }
  