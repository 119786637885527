/* Styles for the gallery grid */
.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 15px;
    padding: 10px;
  }
  
  .gallery-item {
    position: relative;
    cursor: pointer;
  }
  
  /* To ensure consistent image shape and size (square in this case) */
  .gallery-thumbnail-wrapper {
    position: relative;
    width: 100%;
    padding-bottom: 100%; /* This maintains a 1:1 aspect ratio (square) */
    overflow: hidden;
    border-radius: 5px;
  }
  
  .gallery-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the area without distorting */
    transition: transform 0.3s ease;
  }
  
  .gallery-thumbnail:hover {
    transform: scale(1.05);
  }
  
  /* Styles for the image popup */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  
  .overlay-content {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 90%;
    max-height: 90%;
  }
  
  .popup-image {
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2rem;
    color: white;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px 10px;
    border-radius: 50%;
  }
  
  .close-btn:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  /* Responsive styles for mobile and laptop */
  @media (max-width: 768px) {
    .gallery-grid {
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    }
  
    .popup-image {
      max-width: 100%;
      max-height: 80%;
    }
  
    .close-btn {
      font-size: 1.5rem;
    }
  }
  
  @media (min-width: 769px) {
    .gallery-grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  
    .popup-image {
      max-width: 80%;
      max-height: 80%;
    }
  }
