* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.services-container {
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    background-color: #ffffff;
}

.services-heading {
    font-size: 2em;
    color: #000;
    margin-bottom: 20px;
}

.services-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: 15px;
}

.service-card {
    flex: 1;
    min-width: 300px;
    max-width: 500px;
    background-color: #ffffff;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-image {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-bottom: 2px solid #f5f5f5;
}

.card-title {
    font-size: 1.25em;
    text-align: left;
    color: #000;
    padding: 10px 15px;
}

.card-text {
    font-size: 0.9em;
    color: #333;
    padding: 0 15px 15px;
    text-align: left;
}

/* Loading animation styles */
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px; /* Match the height of the card */
}

.loader div {
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 50%;
    background-color: #3498db;
    animation: loaderAnimation 0.6s infinite alternate;
}

.loader div:nth-child(2) {
    animation-delay: 0.2s;
}

.loader div:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes loaderAnimation {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-20px);
    }
}

/* Responsive */
@media (max-width: 768px) {
    .services-cards {
        flex-direction: column;
    }

    .service-card {
        max-width: 100%;
        width: calc(100% - 3px); /* Ensuring proper width on smaller screens */
        margin: 0 auto;
    }

    .card-image {
        min-height: 250px;
        max-height: 250px;
    }
}
