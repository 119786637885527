.hero-section {
    color: black;
    background-blend-mode: overlay;
    overflow: hidden;
    min-height: 700px; /* Consistent height */
    display: flex;
    align-items: center;
    padding: 10px;
    height: 100vh;
}

.hero-section__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /* background-color: rgba(255, 255, 255, 0.8); */

    border-radius: 10px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    min-height: 450px; /* Ensure consistent height */
    animation: fadeInContainer 1s ease forwards; /* Container fade-in */
}

@keyframes fadeInContainer {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.hero-section__left-content {
    text-align: left;
    flex: 1;
    padding: 20px;
    animation: slideInLeft 1s ease forwards; /* Slide in from left */
    opacity: 0;
}

@keyframes slideInLeft {
    0% { transform: translateX(-100%); opacity: 0; }
    100% { transform: translateX(0); opacity: 1; }
}

.hero-section__title {
    font-size: 3rem;
    margin: 10px 0;
    color: #00796b;
}

.hero-section__subtitle {
    color: #333333;
    font-size: 1.2rem;
    margin: 15px 0;
}

.hero-section__description {
    color: #555555;
    font-size: 1rem;
    margin: 15px 0;
}

.hero-section__right-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 20px;
    animation: slideInRight 1s ease forwards; /* Slide in from right */
    opacity: 0;
}

@keyframes slideInRight {
    0% { transform: translateX(100%); opacity: 0; }
    100% { transform: translateX(0); opacity: 1; }
}

.hero-section__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.hero-section__image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    max-width: 500px;
    opacity: 0;
    animation: fadeInImage 1.2s ease forwards 0.5s; /* Delayed image fade-in */
}

@keyframes fadeInImage {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.hero-section__call-to-action {
    margin-top: 20px;
}

.hero-section__apply-button {
    background-color: #00796b;
    color: #fff;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.4), 0 0 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;

}

.hero-section__apply-button:hover {
    background-color: #004d40;
}

/* Responsive Styles */
@media (max-width: 992px) {
    .hero-section__container {
        flex-direction: column;
    }

    .hero-section__title {
        font-size: 2.5rem;
    }
}

@media (max-width: 768px) {
    .hero-section__title {
        font-size: 2rem;
    }

    .hero-section__apply-button {
        padding: 10px 20px;
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .hero-section__title {
        font-size: 1.5rem;
    }

    .hero-section__apply-button {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}
