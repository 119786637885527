.service-detail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.service-detail-banner {
    width: 100%;
    /* max-height: 400px; */
    object-fit: cover;
    border-radius: 8px;
}

.service-detail-content {
    padding: 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    margin-top: 20px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-detail-title {
    font-size: 28px;
    color: #2c3e50;
    font-weight: 700;
    margin-bottom: 10px;
}

.service-detail-subtitle {
    font-size: 20px;
    color: #16a085;
    font-weight: 500;
    margin-bottom: 15px;
}

.service-detail-description {
    font-size: 16px;
    color: #7f8c8d;
    line-height: 1.6;
    margin-bottom: 20px;
}

.service-detail-details {
    background-color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.service-detail-details h3 {
    color: #2980b9;
    font-size: 18px;
    margin-bottom: 10px;
}

.service-detail-details p {
    color: #34495e;
    line-height: 1.6;
}

.service-detail-more-info {
    background-color: #ecf0f1;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-detail-more-info h3 {
    color: #c0392b;
    font-size: 18px;
    margin-bottom: 10px;
}

.service-detail-more-info p {
    color: #2c3e50;
    line-height: 1.6;
}
