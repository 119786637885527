.about-us-home-container{
    background-color: #f8f9fa;

}

.about-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f8f9fa;
    max-width: 800px;
    margin: 0 auto;
}

.about-us-header {
    text-align: center;
    margin-bottom: 20px;
}

.about-us-doctor-image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #4CAF50;
}

.about-us-doctor-name {
    margin-top: 15px;
    font-size: 1.5em;
    color: #333;
}

.about-us-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
}

.about-us-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    flex: 1 1 45%; /* Ensures two cards per row */
    min-width: 300px;
    text-align: center;
}

.about-us-clinic-title {
    font-size: 1.2em;
    font-weight: bold;
    color: #4CAF50;
    margin-bottom: 10px;
}

.about-us-clinic-address {
    font-size: 1em;
    color: #555;
    line-height: 1.4;
    margin-bottom: 20px;
}

.about-us-appointment-button {
    background-color: #4CAF50;
    color: #fff;
    border: none;
    padding: 10px 15px;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.about-us-appointment-button:hover {
    background-color: #388e3c;
}
