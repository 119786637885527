.conditions-treated {
    padding: 20px;
    background-color: #f8f9fa;
    text-align: center;
  }
  
  .conditions-heading {
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 30px;
    color: #333;
  }
  
  .conditions-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    justify-content: center;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .condition-item {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .condition-item:hover {
    transform: translateY(-5px);
  }
  
  .condition-icon {
    font-size: 3em;
    color: #28a745; /* Green color */
    margin-bottom: 15px;
  }
  
  .condition-item h3 {
    font-size: 1.4em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .condition-item p {
    font-size: 1em;
    color: #555;
  }
  
  .treatment-options {
    max-width: 1200px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .treatment-heading {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .treatment-details div {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .treatment-details h4 {
    font-size: 1.2em;
    font-weight: bold;
    color: #28a745;
    margin-bottom: 10px;
  }
  
  .treatment-details ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #555;
  }
  
  @media (max-width: 768px) {
    .condition-item {
      width: 100%;
      flex-direction: column;
      padding: 30px;
    }
  
    .conditions-container {
      grid-template-columns: 1fr;
      gap: 15px;
    }
  
    .treatment-options {
      padding: 15px;
    }
  }
  