.home-page-owl-carousel-features {
    padding: 40px 0;
  }
  
  /* Section Title */
  .section-title h2 {
    color: #333;
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .section-title p {
    color: #666;
    font-size: 1rem;
    text-align: center;

  }
  
  /* Desktop View */
  .treatment-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px 0px;
  }
  
  /* Feature Item */
  .home-page-owl-carousel-single-features {
    background-color: #f9f9f9;
    border-radius: 8px;
    text-align: center;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .home-page-owl-carousel-single-features:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
  
  .home-page-owl-carousel-single-icon img {
    width: 150px;
    height: 150px;
    margin-bottom: 15px;
    object-fit: cover;
  }
  
  /* Mobile View (Carousel Behavior) */
  @media (max-width: 768px) {
    .treatment-grid {
      display: flex;
      overflow-x: auto;
      /* scroll-snap-type: x mandatory; */
      -webkit-overflow-scrolling: touch;
      padding-bottom: 20px; /* Adds padding to prevent clipping */
      gap: 0;
    }
  
    .home-page-owl-carousel-single-features {
      flex: 0 0 95%; /* Each card takes 80% of the screen width */
      margin: 10px;
      scroll-snap-align: center;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
  
    /* Smoother scrolling experience */
    .treatment-grid {
      scroll-behavior: smooth;
    }
  
    /* Hiding carousel navigation arrows on mobile */
    .carousel-navigation {
      display: none; /* Completely remove the navigation arrows */
    }
  }
  
  /* Smallest screens (phone) */
  @media (max-width: 480px) {
    .section-title h2 {
      font-size: 1.5rem;
    }
  
    .section-title p {
      font-size: 0.9rem;
    }
  
    .home-page-owl-carousel-single-features {
      padding: 15px;
    }
  }
  