/* General Container */
.homepage-stats-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    background-color: #1d4f20; /* Adjust color */
    border-radius: 0px;
    width: 100%;
  }
  
  /* Desktop View */
  .homepage-stats-desktop-view {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 20px;
    width: 100%;
  }
  
  /* Mobile View */
  .homepage-stats-mobile-view {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two icons per row */
    gap: 15px;
    padding: 20px;
    width: 100%;
  }
  
  .homepage-stats-item {
    text-align: center;
    color: white;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Icons Styling */
  .homepage-stats-icon {
    font-size: 2.5rem;
    margin-bottom: 10px;
    border: 2px solid white; /* White border around the icon */
    padding: 10px;
    border-radius: 50%;
    width: 60px; /* Fixed size for the icon */
    height: 60px; /* Fixed size for the icon */
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn 1s ease-in-out; /* Add animation */
    background-color: transparent;
  }
  
  /* Keyframes for Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Text Styling */
  .homepage-stats-number {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 5px 0;
  }
  
  .homepage-stats-label {
    font-size: 1rem;
  }
  
  /* Responsive Behavior */
  @media only screen and (min-width: 769px) {
    .homepage-stats-mobile-view {
      display: none;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .homepage-stats-desktop-view {
      display: none;
    }
    
    .homepage-stats-mobile-view {
      display: grid; /* Ensure it appears as grid on mobile */
    }

    .homepage-stats-container{
        padding: 0%;
    }
  }
  